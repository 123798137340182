import { Vue, Component, Prop } from "vue-property-decorator";
import APP_UTILITIES from "@/utilities/commonFunctions";
import APP_CONST from "@/constants/AppConst";

export type CMSDropdownOption = {
  label: string;
  value: string;
  isChecked?: boolean;
  disable?: boolean;
  meta?: any;
  options?: Array<CMSDropdownOption>;
};

export type DropdownData = {
  title: string;
  disabled?: boolean;
  options: Array<CMSDropdownOption>;
};

@Component({
  name: "cms-multiselect-dropdown",
  props: {
    dropdownData: {
      type: Object
    }
  }
})
export default class CmsMultiSelectDropdown extends Vue {
  isOpen!: boolean;

  @Prop({
    required: true,
    default: () => ({
      title: "Title"
    })
  })
  dropdownData!: DropdownData;

  isDropped: boolean = false;

  addedItemsList: Array<CMSDropdownOption> = [];

  droppedSubmenus: Array<string> = [];

  lxEnhancementsEnabled: boolean = false;

  openSubmenu(role: CMSDropdownOption) {
    if (!role.options || !role.options.length) return;
    const index = this.droppedSubmenus.findIndex(v => v === role.value);
    if (index === -1) {
      this.droppedSubmenus.push(role.value);
    } else {
      this.droppedSubmenus.splice(index, 1);
    }
  }

  selectItem(item: CMSDropdownOption) {
    if (this.addedItemsList.find(x => x.value === item.value) === undefined) {
      this.addedItemsList.push(item);
    }

    /* Sub-items logic: auto-select child items */
    if (item.options && item.options.length) {
      item.options = item.options.map((option: CMSDropdownOption) => ({ ...option, isChecked: item.isChecked }));
    }

    this.$emit("onSelectionChange", {
      id: item.value,
      value: item.value,
      checked: item.isChecked,
      options: item.options
        ? item.options.filter((x: CMSDropdownOption) => x.isChecked)
          .map((x: CMSDropdownOption) => x.value)
        : undefined
    });
  }

  selectSubItem(item: CMSDropdownOption, parent: CMSDropdownOption) {
    if (item.isChecked) {
      parent.isChecked = true;
    } else if (parent.options && parent.options.reduce((sum, x) => (x.isChecked ? sum + 1 : sum), 0) === 0) {
      parent.isChecked = false;
    }

    if (parent.isChecked && this.addedItemsList.find(x => x.value === parent.value) === undefined) {
      this.addedItemsList.push(parent);
    }

    // TODO: Send child options
    this.$emit('onSelectionChange', {
      id: parent.value,
      value: parent.value,
      checked: parent.isChecked,
      options: parent.options
        ? parent.options.filter((x: CMSDropdownOption) => x.isChecked)
          .map((x: CMSDropdownOption) => x.value)
        : undefined,
    });
  }

  getCheckboxClass(item: CMSDropdownOption) {
    if (!item.options || !item.options.length) return "";
    const options = item.options || [];
    const selected = options.reduce((sum: number, option: CMSDropdownOption) => (option.isChecked ? sum + 1 : sum), 0) || 0;
    return selected > 0 && selected < options.length ? "partial-selection-checkbox" : "";
  }

  dataUpdate() {
    const selected = this.dropdownData.options.filter(
      item => item.isChecked === true
    );
    this.addedItemsList = this.dropdownData.options.filter(
      item => item.isChecked === true
    );
  }

  mounted() {
    this.$watch("dropdownData", this.dataUpdate, { deep: true });
  }

  beforeMount() {
    this.dataUpdate();
  }

  async created() {
    this.lxEnhancementsEnabled = await APP_UTILITIES.getFeatureFlag(APP_CONST.FEATURE_KEYS.cmsLxEnhancements);
  }
}
